// Alto Parnaíba
const idPortalApi = "412107223b2fa2a0459f77b3e2f623d676875669";
const idForTransparenciaAdm = "4b25cced-dce6-4c9c-b5ba-d083a8954333";

module.exports = {
  idPortalApi: `${idPortalApi}`,
  tokenLink: `${idPortalApi}`, //Token para acessar o WWW.transparência
  idForTransparenciaAdm: `${idForTransparenciaAdm}`,
  // Produção
  urlApi: `https://api-portais-workcenter.com.br/${idPortalApi}`,
  // urlApi: `http://localhost:8004/${idPortalApi}`,
  urlADM: `https://administracaopublica.com.br`,

  //config botões e imagens
  dnsPortal: "cmbaraodegrajau.ma.gov.br",
  banner_defalt: true, //se não quiser o banner transparencia azul padrao

  NomePortal: "Barão de Grajaú",
  ufPortal: "MA",
  NomeTipoPortal: "Câmara Municipal", //Prefeitura ou Câmara
  TipoPortal: 4, //3 = Prefeitura 4 = Câmara

  //Variável em teste (newsViewRoute)
  newsViewRoute: true, //Essa opção informa se a rota de visualização de uma única noticia está disponível (Raposa gera as noticias pelo lado do servidor com nextjs)

  countNewsHome: 4, //quantidade de noticias na home

  RoutesDaily: true, //Deixa ativado as rotas para a pagina de diario

  link: "http://www.transparenciadministrativa.com.br", //Onde o token abaixo ser[a utilizado]
  linkArquivos: "http://www.transparenciadministrativa.com.br/portalimagem", //Onde o token abaixo ser[a utilizado]

  urlEmDeploy: "https://cmbaraodegrajau.ma.gov.br", //usada para link de compartilhamento
  imagemURL: "https://arquivos-api-portais.s3.sa-east-1.amazonaws.com/", //link para a imagem
  //imagemURL: 'http://localhost:8004/uploads/', //link para a imagem

  corIcones: "#000080",
  link_mapa: `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3090.227113967663!2d-43.024211699999995!3d-6.758353100000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x78362ca7f85e541%3A0xa1fdc82ab4a825f5!2sC%C3%A2mara%20Municipal%20de%20Bar%C3%A3o%20de%20Graja%C3%BA!5e1!3m2!1spt-BR!2sbr!4v1731344368509!5m2!1spt-BR!2sbr`,
};
